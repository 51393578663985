import { defineComponent, onBeforeUnmount, onMounted } from "vue";
import { authStore } from '../../store/modules/auth';
import { DxColumn, DxButton } from 'devextreme-vue/ui/data-grid';
import DataGridFactory from '../../components/datagrid-factory/index.vue'
import eventBus from '../../helpers/event-bus';
import { usuarioStore } from '../../store/modules/usuarios';
import DataSource from 'devextreme/data/data_source';

export default defineComponent({
  name: 'Usuários',
  setup() {
    onMounted(() => {
      dataSourceEvent.listen(ReloadDataSource)
    })

    onBeforeUnmount(() => {
      dataSourceEvent.off(ReloadDataSource)
    })

    const popupEvent = eventBus.usePopup()
    const dataSourceEvent = eventBus.useDataSource()
    const store = usuarioStore()
    const auth = authStore()

    const dataSource = new DataSource({
      key: 'id',
      load:() => store.LOAD()
    })

    async function AddButton() {
      await popupEvent.open({
        component: 'FormUsuario',
        title: 'Adicionar Novo Usuário',
        props: {
          id: 0,
          nome: '',
          email: '',
          senha: '',
          tipoUsuario: 3,
          contabilistaID: null,
          ativo: true,
          isReadonly: false,
        },
        size: { width: 600, height: 415 }
      })
    }

    async function EditButton(e: any) {
      store.$reset()
      const rowKey = e.row.key;
      if (rowKey) await store.GET_USER_BY_ID(rowKey)
      await popupEvent.open({
        component: 'FormUsuario',
        title: `Dados do Usuário: ${store.usuario.nome}`,
        props: Object.assign(store.usuario, { isReadonly: true }),
        size: { width: 600, height: 335 }
      })
    }


    async function ResetPassword(e: any) {
      const usuarioNewPassword = {
        id: e.row.key,
        password: '',
        tipoUsuario: auth.currentUser.tipo
      }
      await popupEvent.open({
        component: 'FormResetSenha',
        title: 'Alteração de Senha',
        props: usuarioNewPassword,
        size: { width: 600, height: 195 }
      })
    }

    function ReloadDataSource() {
      dataSource.reload()
    }

    return {
      dataSource,
      AddButton,
      EditButton,
      ResetPassword
    }
  },
  components: {
    DataGridFactory,
    DxColumn,
    DxButton,
  }
})