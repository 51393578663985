<template>
  <div>
    <p class="title">Usuários</p>
    <hr />
    <DataGridFactory
      :dataSource="dataSource"
      :AddButtonFunction="AddButton"
      :EditButtonFunction="EditButton"
    >
      <template v-slot:button>
        <DxButton
          hint="Altera Senha"
          icon="mdi mdi-account-key mdi-18px"
          :on-click="ResetPassword"
        />
      </template>

      <template v-slot:column>
        <DxColumn
          alignment="left"
          data-field="nome"
          sort-order="asc"
        />
        <DxColumn alignment="center" data-field="email" />
        <DxColumn data-field="ativo" />
      </template>
    </DataGridFactory>
  </div>
</template>
<script lang="ts" src="./index.ts"></script>